<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { createPostRequest, createGetRequest } from '../src/create_request'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()
  import Address from '../src/address.svelte'
  import Lister from '../src/lister.svelte'
  import InPlaceEditor from '../src/in_place_editor.svelte'
  import { _ } from 'svelte-i18n'
  import { formatCurrency } from '../src/utils.js'
  import OrderStatusIcon from './svg/dot.svelte'
  import PackingSlip from './packing_slip.svelte'

  let currentShop
  let showing = false
  let currentOrder
  let showingOrder = false

  function mountFromData(data, pushState = false) {
  }

  onMount(async () => {

  })

  function handle_show(item) {
    currentShop = item
    showing = true
    console.log('handle_show')
    console.log(currentShop)
  }

  function handle_list(item) {
    showing = false
    console.log('handle_list')
  }

  function handle_show_order(item) {
    currentOrder = item
    showingOrder = true
    console.log('handle_show_order')
    console.log(currentOrder)
  }

  function handle_list_order(item) {
    showingOrder = false
    console.log('handle_list_order')
  }

  function handle_webpage_update() {
    console.log('handle_webpage_update')
  }


  function handle_mark_as_shipped() {
    console.log('handle_mark_as_shipped')

    fetch(createPostRequest('/shops/'+ currentShop.id + '/orders/' +  currentOrder.id + '/ship.json',
                        'POST',
                        JSON.stringify({order: {shipped: true}})))
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        } else {
          currentOrder = data
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_mark_as_closed() {
    console.log('handle_mark_as_closed')

    fetch(createPostRequest('/shops/'+ currentShop.id + '/orders/' +  currentOrder.id + '/close.json', 'POST'))
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        } else {
          currentOrder = data
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }
</script>

<style>

</style>

<Lister fetch_items_path = '/shops.json'
        search_items_path = '/shops/search.json'
        create_item_path = '/shops.json'
        show_item_path = '/shops'
        item_name = 'Shop'
        items_name = 'Choose shop'
        list_key = 'shops'
        enable_show = {true}
        enable_visit = {false}
        enable_create = {false}
        show_handler = {handle_show}
        back_handler = {handle_list}>
  <div slot="show">
    {#if showing && currentShop}
      <Lister fetch_items_path = {'/shops/' + currentShop.id + '/orders.json'}
              search_items_path = {'/shops/' + currentShop.id + '/orders/search.json'}
              create_item_path = {'/shops/' + currentShop.id + '/orders.json'}
              show_item_path = {'/shops/' + currentShop.id + '/orders'}
              item_name = 'Order'
              items_name = 'Choose order'
              list_key = 'orders'
              enable_show = {true}
              enable_visit = {false}
              enable_create = {false}
              show_handler = {handle_show_order}
              back_handler = {handle_list_order}
              let:item={order}>
        <div slot="title">
          <div class="flex flex-row items-start text-xs sm:text-sm">
            <OrderStatusIcon fill={currentOrder.status_color} alt={currentOrder.status} blink={currentOrder.status === 'submitted' && currentOrder.paid}/>
            <div class="ml-1 flex flex-col text-xs sm:text-sm">
              <div class="flex items-center">{currentOrder.status_at_fs_full || ''}</div>
              <div class="order-email mt-1 flex items-center">{currentOrder.user.email || ''}</div>
            </div>
          </div>
        </div>

        <div class="order-created-at flex flex-row items-start m-1 text-xs sm:text-sm">
          <OrderStatusIcon fill={order.status_color} alt={order.status} blink={order.status === 'submitted' && order.paid}/>
          <div class="ml-1 flex flex-col text-xs sm:text-sm">
            <div class="flex items-center">{order.status_at_fs_full || ''}</div>
            <div class="order-email mt-1 flex items-center">{order.user.email || ''}</div>
          </div>
        </div>

        <div slot="show">
          {#if showingOrder && currentOrder}
            <div class="mt-3">
              {#each currentOrder.order_items as item}
                <div class="flex flex-row items-center justify-end gap-2">
                  <div class="kich-title {item.kich.kind}">{item.kich.title}</div>
                  {#if item.kich.kind === 'tangible'}
                    <div>{formatCurrency(item.kich.price, $settings.locale, $settings.currency, 2, 0)}</div>
                  {:else}
                    <div>{formatCurrency(0, $settings.locale, $settings.currency, 2, 0)}</div>
                  {/if}
                </div>
              {/each}
              <div class="flex flex-col border-t-2 mt-1">
                <div class="flex flex-row justify-end gap-4 mt-1">
                  <div class="flex items-center">{$_('total')}: {formatCurrency(currentOrder.total, $settings.locale, $settings.currency, 2, 0)}</div>
                </div>
              </div>
            </div>
            {#if currentOrder.shipping_address}
              <div class="mt-3">
                <Address title='Shipping Address' address={currentOrder.shipping_address} show_address={false}/>
              </div>
            {/if}
            {#if currentOrder.billing_address}
              <div class="mt-3">
                <Address title='Billing Address' address={currentOrder.billing_address} show_address={false}/>
              </div>
            {/if}
            {#if currentOrder.status === 'submitted' }
              <div class="mt-3">
                <PackingSlip order={currentOrder}/>
              </div>
            {/if}
            <div class="mt-8 flex flex-col justify-center items-center">
              {#if currentOrder.status === 'submitted'}
                {#if currentOrder.paid}
                  <button class="btn" on:click={handle_mark_as_shipped}>Mark as shipped</button>
                {:else}
                  <div>Waiting for payment.</div>
                {/if}
              {:else if currentOrder.status === 'shipped'}
                <button class="btn" on:click={handle_mark_as_closed}>Mark as closed</button>
              {:else if currentOrder.status === 'closed'}
                This order was successfully closed.
              {/if}
            </div>
          {/if}
        </div>
      </Lister>
    {/if}
  </div>
</Lister>


