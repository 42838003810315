<script>
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()
  import { createEventDispatcher, onMount } from 'svelte'
  import { createPostRequest } from '../src/create_request'
  import { focus, formatCurrency } from '../src/utils'
  import { _, locales, locale as systemLocale } from 'svelte-i18n'
  import CurrencyInput from '../src/currency_input.svelte'
  import Trix from "trix"
  import CryptoConvert from 'crypto-convert'

  export let clazz //, required = true
  export let name //, required = true // attribute name
  export let title //
  export let value, required = true
  export let type = null
  export let url //, required = true
  export let update_handler
  export let locale
  export let currency
  export let collection
  export let use = ()=>{}

  let form
  let input
  let trix
  let errors
  const dispatch = createEventDispatcher()
  let editing = false, original
  let submitted = false
  let formattedCurrency
  let converter
  let showConverter = false

  onMount(() => {
    original = value

    console.log('InPlaceEditor onMount:')
    console.log('Locale: ' + locale)
    console.log('Currency: ' + currency)

    if (!locale){
      locale = $settings.locale
      currency = $settings.currency
    }
    //$systemLocale = locale
    if (type && type === 'currency') {
      if (!window.converter) {
        converter = new CryptoConvert()
        window.converter = converter
        awaitConverter()
      } else {
        showConverter = true
        formattedCurrency = formatCurrency(value, locale, currency, 2, 0)
      }
      //converter = new CryptoConvert({
      //  cryptoInterval: 5000, //Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
      //  fiatInterval: (60 * 1e3 * 60), //Fiat prices update interval (default every 1 hour)
      //  calculateAverage: true, //Calculate the average crypto price from exchanges
      //  binance: true, //Use binance rates
      //  bitfinex: true, //Use bitfinex rates
      //  coinbase: true, //Use coinbase rates
      //  kraken: true, //Use kraken rates
      //  onUpdate: (tickers, isFiatUpdate?)=> any //Callback on every crypto update
      //  HTTPAgent: null //HTTP Agent for server-side proxies (Node.js only)
      //})
    }
  })

  async function awaitConverter() {
    await converter.ready()

    console.log('onMount inPlaceEditor ' + locale + ' ' + currency + ' ' + formattedCurrency)
    console.log(converter)
    formattedCurrency = formatCurrency(value, locale, currency, 2, 0)

    showConverter = true
  }

  function edit() {
    editing = true
    submitted = false
  }

  function submit() {
    console.log('in place submit')

    if (value != original) {
      dispatch('submit', value)
    }

    editing = false
  }

  function keydown(event) {
    if (event.key == 'Escape') {
      event.preventDefault()
      value = original
      editing = false
    }
  }

  function handle_select_selection() {
    console.log('handle_select_selection ' + value)
    handle_direct_update()
  }

  function handle_locale_selection() {
    console.log('handle_locale_selection')
    formattedCurrency = formatCurrency(value, locale, currency, 2, 0)

    console.log('!handle_currency_'+name+'_update in place: ' + value)
    if (clazz && name && url) {
      console.log('!handle_'+name+'_update in place posting...')
      let updateData = {[clazz]: {locale: locale, currency: currency}}
      fetch(createPostRequest(url,
                          'PUT',
                          JSON.stringify(updateData)))
        .then(response => response.json())
        .then(data => {
          if (data.errors) {
            errors = data.errors
            alert(data.errors)
          } else {
            if (update_handler) {
              update_handler(updateData)
            }
          }
        })
        .catch(err => {
          alert('error ' + err)
        })
    }

  }

  function handle_currency_update() {
    handle_update()
  }

  function handle_direct_update() {

    console.log('InPlaceEditor handle_direct_update ' + value)

    //if (value != original) {
      if (clazz && name && url) {
        let updateData = {[clazz]: {[name]: value}}
        fetch(createPostRequest(url,
                            'PUT',
                            JSON.stringify(updateData)))
          .then(response => response.json())
          .then(data => {
            if (data.errors) {
              errors = data.errors
              alert(data.errors)
            } else {
              editing = false
              if (update_handler) {
                update_handler(data)
              }
            }
          })
          .catch(err => {
            alert('error ' + err)
          })
      } else {
        submit()
      }
    //} else {
    //  console.log('resetting value to original')
    //  value = original
    //}
  }

  function handle_update() {
    if (submitted) {
      return
    }

    if (type === 'trix' && trix) {
      value = input && input.value // making Trix work, since it does not update the bind:value
    }

    submitted = true

    console.log('InPlaceEditor handle_update ' + value)

    if (value != original) {
      if (clazz && name && url) {
        let updateData = {[clazz]: {[name]: value}}
        fetch(createPostRequest(url,
                            'PUT',
                            JSON.stringify(updateData)))
          .then(response => response.json())
          .then(data => {
            if (data.errors) {
              errors = data.errors
              alert(data.errors)
            } else {
              editing = false
              if (update_handler) {
                update_handler(updateData)
              }
            }
          })
          .catch(err => {
            alert('error ' + err)
          })
      } else {
        submit()
      }
    } else {
      console.log('resetting value to original')
      value = original
      editing = false
    }
  }

  // https://trix-editor.org/js/attachments.js

  function uploadTrixAttachment(event) {
    console.log('uploading...')
    let formData = new FormData();
    formData.append('shop['+name+']', fileInput.files[0])

    fetch(createUploadRequest('/shops/'+shop.id+'/upload.json',
                        'POST',
                        formData))
      .then(response => response.json())
      .then(data => {

        //var attributes = {
        //  url: HOST + key,
        //  href: HOST + key + "?content-disposition=attachment"
       // }
        //successCallback(attributes)

        if (data.errors) {
          alert(data.errors)
        } else {
          console.log('uploaded image '+name+':')
          console.log(data)
          shop = data
          if (update_handler) {
            update_handler()
          }
          clearUploadForm()
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_attachment_add(event) {
    console.log('handle_attachment_add ' + event.attachment)
    //  TODO: upload and set the url on the attachment

  }

  $: if (value != original) {console.log('value: ' + value)}
  $: currency = locale?$_('currency', {locale: locale}):'USD'
</script>
<style>
  trix-editor {
    border: 0;
  }
</style>

{#if editing}
  {#if type === 'trix'}
    <div class="relative {$$restProps.class || ''}">
      <label for="inplace-input" class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-white dark:text-black font-bold px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</label>

      <div class="block px-0 pb-2.5 pt-4 px-2 mx-1 text-sm bg-white border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
        <input id="trix-{clazz}-{name}" name="{name}" type="hidden" bind:value={value} bind:this={input}/>
        <trix-editor input="trix-{clazz}-{name}" bind:this={trix} class="text-black p-1" use:focus on:trix-attachment-add={handle_attachment_add}/>
      </div>

      <div class="flex flex-row justify-end"><button type="submit" class="btn m-1" on:click={handle_update}>Save</button></div>
    </div>
  {:else if type === 'currency'}
    <form bind:this={form} on:submit|preventDefault|stopPropagation={handle_currency_update} on:keydown={keydown}>
      <div class="relative {$$restProps.class || ''}">
        <label for="inplace-input" class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 font-bold px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</label>
        <CurrencyInput name="{name}" bind:formattedValue={formattedCurrency} bind:value {locale} {currency} update_handler={()=>{console.log('currency value changed')}} inputClass="block px-0 pb-2.5 pt-4 mx-1 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
        {#if showConverter}
          <div class="absolute top-2 right-1">
            <select bind:value={locale} on:change={handle_locale_selection} class="rounded-md drop-shadow-md hover:bg-gray-100 focus:ring-0 dark:text-black">
              {#each $locales as syslocale}
                <option value={syslocale}>{$_('currency', {locale: syslocale})}</option>
              {/each}
            </select>
          </div>
        {/if}

        <div class="flex flex-row justify-end"><button type="submit" class="btn m-1">Save</button></div>
      </div>
    </form>
  {:else}
    <form on:submit|preventDefault|stopPropagation={handle_update} on:keydown={keydown}>
      <div class="relative {$$restProps.class || ''}">
        <label for="inplace-input" class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 font-bold px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</label>

        <input id="inplace-input" bind:value on:blur={handle_update} {required} use:focus class="block px-2.5 pb-2.5 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>

        <div class="flex flex-row justify-end"><button type="submit" class="btn m-1">Save</button></div>
      </div>
    </form>
  {/if}

  {#if (errors && errors[field])}({errors[field]}){/if}
  {#if (errors && errors['base'])}
    <div>
      <label/>
      <span>{errors['base']}</span>
    </div>
  {/if}
{:else}
  {#if type === 'trix'}
    <div on:click={edit} class="relative {$$restProps.class || ''}">
      <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 px-1 font-bold start-1">{title}</div>

      <div class="block px-2 pb-2 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 overflow-hidden max-h-12">{@html value || 'Click here to edit '}</div>
    </div>
  {:else if type === 'currency'}
    <div on:click={edit} class="relative {$$restProps.class || ''}">
      <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 px-1 font-bold start-1">{title}</div>

      <div class="block px-2 pb-2 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600">
        {#if (value)}
          {formattedCurrency}
        {:else}
          {'Click here to edit ' + name}
        {/if}
      </div>
    </div>
  {:else if type === 'select'}
    <div class="relative {$$restProps.class || ''}">
      <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 px-1 font-bold start-1">{title}</div>

      <select {required} bind:value on:change={handle_select_selection} class="block px-2.5 pb-2.5 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
        {#each collection as aOption}
          <option value={aOption.value} selected={aOption.value === value}>{aOption.title}</option>
        {/each}
      </select>
    </div>
  {:else if type === 'boolean'}
    <div class="relative {$$restProps.class || ''}">
      <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 px-1 font-bold start-1">{title}</div>
        <div class="mt-1">
          <input id="inplace-input" type="checkbox" bind:checked={value} on:change={handle_direct_update} {required} use:focus class="w-full focus:outline-none focus:ring-0"/>
        </div>
    </div>
  {:else if type === 'secret'}
    <div on:click={edit} class="relative {$$restProps.class || ''}">
      <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 px-1 font-bold start-1">{title}</div>

      <div class="block px-2 pb-2 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600">{'Click here to edit '}</div>
    </div>
  {:else}
    <div on:click={edit} class="relative {$$restProps.class || ''}">
      <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 px-1 font-bold start-1">{title}</div>

      <div class="block px-2 pb-2 pt-4 w-full text-sm bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600">{value || 'Click here to edit '}</div>
    </div>
  {/if}
{/if}
