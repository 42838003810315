<script>
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()
  import { createEventDispatcher, onMount } from 'svelte'
  import { createPostRequest, createUploadRequest } from '../src/create_request'
  import { focus, formatCurrency } from '../src/utils'

  export let shop
  export let image
  export let name
  export let title
  export let update_handler

  let uploadForm
  let fileInput
  let file // temp
  let selectedFile
  let uploadingFile
  let errors = false

  function clearUploadForm() {
    // uploadForm.reset()
    file = null
    selectedFile = null
    uploadingFile = null
    fileInput.value = null
  }

  function upload(event) {
    console.log('uploading...')
    let formData = new FormData();
    formData.append('shop['+name+']', fileInput.files[0])

    fetch(createUploadRequest('/shops/'+shop.id+'/upload.json',
                        'POST',
                        formData))
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        } else {
          console.log('uploaded image '+name+':')
          console.log(data)
          shop = data
          if (update_handler) {
            update_handler()
          }
          clearUploadForm()
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function readImage(file) {
    var reader = new FileReader();
    reader.onload = function (event) {
      uploadingFile = {name: file.name, size: file.size, src: event.target.result}
    }
    reader.readAsDataURL(file)
  }

  // when file input is populated run this reactive code to read and display images before they get uploaded
  $: if (selectedFile) {
    // Note that `files` is of type `FileList`, not an Array:
    // https://developer.mozilla.org/en-US/docs/Web/API/FileList
    console.log(fileInput.files[0])
    // console.log(`${selectedFile.name}: ${selectedFile.size} bytes`)
    readImage(fileInput.files[0])
  }

  let previewDiv
  function scrollIntoView() {
    //const el = target.element
    //if (!el) return
    setTimeout(() => {
      if (previewDiv) {
        previewDiv.scrollIntoView({behavior: 'smooth'})
      }
    }, 1000)
  }

  let csfr
  let csrfToken = document.head.querySelector("[name='csrf-token']")
  if (csrfToken && csrfToken['content']) { csfr = csrfToken['content'] }
</script>

<div class="relative {$$restProps.class || ''}">
  <label for="inplace-input" class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-red-800 font-bold px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</label>

  <div class="image-{name} mt-3 p-2 flex-row flex-wrap">
    {#if image}
      <img src={image.src} alt={image.name} class="{name.includes('dark')? 'bg-black' : 'bg-white'}"/>
    {/if}
  </div>

  <div class="upload-form p-2 dark:bg-black">
    <form method="POST"
          action="{'/shops/'+shop.id+'/upload'}"
          enctype="multipart/form-data"
          bind:this={uploadForm}
          on:submit|preventDefault={upload}>
      <input type="hidden" name="authenticity_token" value={csfr}/>

      <input title="AHA" bind:this={fileInput} bind:value={selectedFile} id="logo-upload" type="file" name="shop[logo_light]" on:click={() => {uploadingFile=null}}/>

      <div class="mt-1">
        {#if selectedFile}
          {#if uploadingFile}
            <div bind:this={previewDiv} class="shop-image flex flex-col p-4" use:scrollIntoView>
              <div class="mb-4 flex flex-row justify-between">
                <strong>Previewing:</strong> {uploadingFile.name} ({uploadingFile.size} bytes)
                <button type="submit" class="btn">Upload</button>
              </div>
              <img src="{uploadingFile.src}" alt="{uploadingFile.name}" class="{uploadingFile.name.includes('dark')? 'bg-black' : 'bg-white'}"/>
            </div>
          {/if}
        {/if}
      </div>
    </form>
  </div>
</div>